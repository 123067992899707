import React from "react";

import "../Assets/css/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      Desenvolvido por
      <a href="https://sidneyfilho.com.br/"> Sidney Filho</a>
    </div>
  );
};

export default Footer;
